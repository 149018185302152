import React, { useState, useEffect, Suspense } from "react";
import * as Sentry from "@sentry/react";
import Amplify, { Auth } from "aws-amplify";
import { useSession } from "./_actions/authActions";
import { Routes, Route, Navigate } from "react-router-dom";
import awsconfig from "../aws-exports";
import "./App.css";
import "./utils/logger";
import Layout from "./pages/layout";
import Reports from "./pages/reports";
import Login from "./pages/login";
import Signup from "./pages/signup/index";
import ForgotPassword from "./pages/forgot_password";
import Contacts from "./pages/contacts";
import ContactInfo from "./pages/contacts/ContactInfo";
import Contact from "./pages/contact";
import Profile from "./pages/profile";
import Properties from "./pages/properties";
import Integrations from "./pages/integrations";
import Receipts from "./pages/receipts";
import Users from "./pages/users";
import Inventory from "./pages/inventory";
import es from "date-fns/locale/es";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import splitbee from "@splitbee/web";
import VerifyAccount from "./pages/signup/verify-account";
import Confirmation from "./pages/signup/confirmation";
import Facturacion from "@/pages/signup/facturacion";
import SignupDataConfirm from "@/pages/signup/confirmation";
import Resumen from "@/pages/signup/resumen";
import Return from "./pages/signup/return";
import Mica from "./pages/mica";
import ProListings from "./pages/pro-listings";
import TagManager from "react-gtm-module";

registerLocale("es", es);
setDefaultLocale("es");

Amplify.configure(awsconfig);

splitbee.init({
  // To use Splitbee on another subdomain.
  // Token can be found in project settings
  token: "RTFCW0ZCGEXT",

  // Enable cookie-less mode. Defaults to `false`
  disableCookie: false,

  // Set custom urls when using proxying
  scriptUrl: "https://cdn.splitbee.io/sb.js",
  apiUrl: "https://hive.splitbee.io",
});

function App() {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GTM_CONTAINER_ID || [],
    events: { sendUserInfo: "userInfo" },
  };
  TagManager.initialize(tagManagerArgs);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />

        <Route path="/login" element={<Login />} />
        <Route path="/unirse" exact element={<Signup />} />
        <Route path="/verify-account" exact element={<VerifyAccount />} />
        <Route path="/confirmation" exact element={<Confirmation />} />
        <Route path="/return" exact element={<Return />} />

        <Route path={"/unirse/facturacion"} exact element={<Facturacion />} />

        <Route
          path={"/unirse/confirmar-datos"}
          exact
          element={<SignupDataConfirm />}
        />
        <Route path={"/unirse/resumen"} exact element={<Resumen />} />

        <Route path={"/forgot-password"} element={<ForgotPassword />} />
        <Route element={<Layout />}>
          <Route
            path={"/contacts"}
            element={
              <RequireAuth>
                <Contacts />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/contacts/:contactId/"}
            element={
              <RequireAuth>
                <ContactInfo />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/properties/*"}
            element={
              <RequireAuth>
                <Properties />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/profile"}
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/receipts"}
            element={
              <RequireAuth>
                <Receipts />
              </RequireAuth>
            }
          />
          <Route
            path={"/reports"}
            element={
              <RequireAuth>
                <Reports />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/users"}
            element={
              <RequireAuth>
                <Users />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/contacto"}
            element={
              <RequireAuth>
                <Contact />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/inventory"}
            element={
              <RequireAuth>
                <Inventory />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/integrations"}
            element={
              <RequireAuth>
                <Integrations />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/mica"}
            element={
              <RequireAuth>
                <Mica />
              </RequireAuth>
            }
          ></Route>
          <Route
            path={"/pro-listings"}
            element={
              <RequireAuth>
                <ProListings />
              </RequireAuth>
            }
          ></Route>
        </Route>
      </Routes>
    </div>
  );
}

function RequireAuth({ children }) {
  const { data, error, isLoading } = useSession();

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="loader mb-4 h-12 w-12 rounded-full border-8 border-t-8 border-gray-200 ease-linear">
          cargando
        </div>
      </div>
    );
  }

  if (error) {
    return <Navigate to={"/login"} state={{ from: location }} />;
  }

  if (data) {
    return children;
  }
}

export default App;
