import React, { useMemo, useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  useStates,
  useMunicipalities,
  useNeighborhoods,
} from "@/_actions/regionActions";
import { Combobox, Transition } from "@headlessui/react";
import { useProperty, updateProperty } from "@/_actions/propertiesActions";
import MapLocation from "./map";
import PropTypes from "prop-types";

const LocationSchema = Yup.object().shape({
  state: Yup.number().required("Selecciona un estado"),
  municipality: Yup.object()
    .shape({
      name: Yup.string().required("El municipio es requerido"),
      id: Yup.number().required(),
    })
    .required("Ingresa una ciudad o municipio"),
  colony: Yup.object()
    .shape({
      name: Yup.string().required("La colonia es requerida"),
      id: Yup.number().required(),
    })
    .required("Ingresa una colonia"),
  interior_number: Yup.string(),
  street: Yup.string().required("Agrega una calle"),
  exterior_number: Yup.string().required(
    "Es necesario agregar un número exterior"
  ),
  latLng: Yup.array()
    .of(Yup.number().required())
    .required("El puntero del mapa es requerido"),
});

export default function Index(props) {
  const { callBack, propertyId, goBack, action } = props;
  const { data } = useProperty(propertyId);
  const memoizedCallback = useMemo(() => callBack, [callBack]);
  const [isLatLngErrorLogged, setIsLatLngErrorLogged] = useState(false);
  const [valuesError, setValuesError] = useState(null);

  const onSubmit = async (values) => {
    try {
      const cleanValues = {
        state: values.state,
        latitude: values?.latLng ? values.latLng[0] : values.latitude,
        longitude: values?.latLng ? values.latLng[1] : values.longitude,
        colony: values.colony.id,
        municipality: values.municipality.id,
        exterior_number: values.exterior_number,
        interior_number: values.interior_number,
        street: values.street,
      };
      const response = await updateProperty(propertyId, cleanValues);

      memoizedCallback(response);
    } catch (err) {
      $log.error(err);
    }
  };
  useEffect(() => {
    if (valuesError && !isLatLngErrorLogged) {
      $log.error("ValuesError:", valuesError);
      setIsLatLngErrorLogged(true);
    }
  }, [valuesError, isLatLngErrorLogged]);

  const cleanProperty = (data) => {
    const newObject = {
      ...data,
      operation_type: data?.operation_type?.map((o) => o.id),
      property_type: data?.property_type?.id,
      state: data?.state?.id || 1,
      municipality: data?.municipality || "",
      colony: data?.colony || "",
      exterior_number: data?.exterior_number || "",
      interior_number: data?.interior_number || "",
    };
    return newObject;
  };

  const propertyData = cleanProperty(data);

  const [state, setState] = useState(propertyData?.state || 1);
  const [municipality, setMunicipality] = useState(
    propertyData?.municipality?.name || ""
  );
  const [municipalityId, setMunicipalityId] = useState(
    propertyData?.municipality?.id || ""
  );
  const [neighborhood, setNeighborhood] = useState(
    propertyData?.colony?.name || ""
  );

  const { data: states } = useStates();

  const { data: neighborhoods } = useNeighborhoods({
    municipality: municipalityId,
  });

  const { data: municipalities } = useMunicipalities({
    state_id: state,
  });

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={LocationSchema}
      initialValues={
        propertyData
          ? { ...propertyData }
          : {
              state: 1,
              street: "",
              exterior_number: "",
              interior_number: "",
              municipality: "",
              colony: "",
            }
      }
      enableReinitialize={true}
    >
      {({ values, isSubmitting, setFieldValue, errors }) => (
        <Form data-splitbee-event={`properties-${action}-location`}>
          {/* <UpdateValues values={values} /> */}
          <div className="w-full grid-cols-2 gap-4 space-y-6 md:grid">
            <div>
              <div className="">
                <label htmlFor="state">Estado</label>
                <Field
                  as="select"
                  className="select"
                  data-testid="state"
                  onChange={(v) => {
                    setFieldValue("state", v.target.value);
                    setState(v.target.value);
                    setFieldValue("municipality", "");
                    setFieldValue("colony", "");
                    setFieldValue("street", "");
                    setFieldValue("exterior_number", "");
                    setFieldValue("interior_number", "");
                  }}
                  name="state"
                >
                  {states?.map((n) => (
                    <option key={n.id} value={n.id}>
                      {n.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="state"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg.name}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="municipality">Municipio</label>
                <Field className="select" name="municipality">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }) => (
                    <Combobox
                      as="div"
                      value={field.value}
                      onChange={(v) => {
                        form.setFieldValue("municipality", v);
                        setMunicipalityId(v.id);
                        setFieldValue("colony", "");
                        setFieldValue("street", "");
                        setFieldValue("exterior_number", "");
                        setFieldValue("interior_number", "");
                      }}
                    >
                      <Combobox.Input
                        data-testid="municipalityInput"
                        autoComplete="off"
                        className="select"
                        onChange={(event) => {
                          setMunicipality(event.target.value);
                        }}
                        displayValue={(m) => m.name || ""}
                      />
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Combobox.Options className="ext absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md  bg-gray-50 p-2 shadow-md">
                          {municipality.length > 3 &&
                            municipalities
                              ?.filter((m) =>
                                m.name
                                  .toLowerCase()
                                  .includes(municipality.toLowerCase())
                              )
                              .map((n) => (
                                <Combobox.Option
                                  key={n.id}
                                  value={n}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? "bg-gray-200 text-gray-900"
                                        : "text-gray-900"
                                    }`
                                  }
                                >
                                  {n.name}
                                </Combobox.Option>
                              ))}
                        </Combobox.Options>
                      </Transition>
                    </Combobox>
                  )}
                </Field>
                <ErrorMessage
                  name="municipality"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg.name}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="colony">Colonia</label>
                <Field className="select" name="colony">
                  {({
                    field, // { name, value, onChange, onBlur }
                    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                  }) => (
                    <Combobox
                      as="div"
                      value={field.value}
                      onChange={(v) => {
                        form.setFieldValue("colony", v);
                      }}
                    >
                      <Combobox.Input
                        autoComplete="off"
                        className="select"
                        data-testid="neighborhoodInput"
                        onChange={(event) => {
                          setNeighborhood(event.target.value);
                          setFieldValue("street", "");
                          setFieldValue("exterior_number", "");
                          setFieldValue("interior_number", "");
                        }}
                        displayValue={(m) => m.name || ""}
                      />
                      <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Combobox.Options className="ext absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md  bg-gray-50 p-2 shadow-md">
                          {neighborhood.length > 3 &&
                            neighborhoods
                              ?.filter((n) =>
                                n.name
                                  .toLowerCase()
                                  .includes(neighborhood.toLowerCase())
                              )
                              .map((n) => (
                                <Combobox.Option
                                  key={n.id}
                                  value={n}
                                  className={({ active }) =>
                                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                      active
                                        ? "bg-gray-200 text-gray-900"
                                        : "text-gray-900"
                                    }`
                                  }
                                >
                                  {n.name}
                                </Combobox.Option>
                              ))}
                        </Combobox.Options>
                      </Transition>
                    </Combobox>
                  )}
                </Field>
                <ErrorMessage
                  name="colony"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg.name}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="street" className="form-label">
                  Calle
                </label>
                <Field
                  as="input"
                  type="text"
                  name="street"
                  className="input"
                  placeholder="Calle"
                  data-testid="street"
                  autoComplete="off"
                ></Field>
                <ErrorMessage
                  name="street"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="exterior_number" className="form-label">
                  Número exterior
                </label>
                <Field
                  as="input"
                  type="text"
                  name="exterior_number"
                  data-testid="exteriorNumber"
                  className="input"
                ></Field>
                <ErrorMessage
                  name="exterior_number"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>
              <div>
                <label htmlFor="exterior_number" className="form-label">
                  Número interior
                </label>
                <Field
                  as="input"
                  type="text"
                  name="interior_number"
                  data-testid="interiorNumber"
                  className="input"
                ></Field>
                <ErrorMessage
                  name="interior_number"
                  render={(msg) => (
                    <div className="text-sm text-red-600">{msg}</div>
                  )}
                />
              </div>
            </div>
            <div>
              <MapLocation
                state={values.state}
                municipality={values.municipality?.name}
                colony={values.colony?.name}
                street={values.street}
                exteriorNumber={values.exterior_number}
                onChange={(e) => setFieldValue("latLng", e)}
              />
              {errors?.latLng && (
                <>
                  {setValuesError(values)}
                  <div className="text-sm text-red-600">{errors?.latLng}</div>
                </>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-3 rounded bg-gray-50 p-3">
            <div>
              <div
                onClick={goBack}
                data-splitbee-event={`properties-${action}-location-back`}
                className="group relative flex w-full cursor-pointer justify-center rounded-md border border-transparent bg-gray-200 py-2 px-4 text-lg  font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2"
              >
                Regresar
              </div>
            </div>
            <div className="text-right">
              <button
                disabled={isSubmitting}
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-blue-800 py-2 px-4 text-lg font-medium text-white hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              >
                Continuar
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

Index.propTypes = {
  callBack: PropTypes.func.isRequired,
  propertyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  goBack: PropTypes.func,
  action: PropTypes.string,
};
